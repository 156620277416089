import { ref } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
export default function useCategories() {
	const loading = ref(false);
	const categories = ref([]);
	const categorie = ref([]);
    const errorMessage = ref("");
    const store = useStore();

	const getCategories = async () => {
		try {
			loading.value = true;
			let response = await axios.get('/packs/categories/');
            if(response.status === 200) {
                categories.value = response.data.results;
                loading.value = false;
                store.commit('SET_CATEGORIES', categories.value)
            }
		} catch (error) {
            loading.value = false;
			if (error.response.status === 422) {
				errorMessage.value = error.response.data.detail;
			} 
            if (error.response.status === 404) {
				errorMessage.value = error.response.data.detail;
			} 
			if (error.response.status === 400) {
				errorMessage.value = error.response.detail;
			} 
			else {
				errorMessage.value = 'Erreur de traitement, vueillez réessayer plus tard.';
			}
        }
	};

	const getCategorie = async (slug) => {
        try {
            loading.value = true;
            let response = await axios.get('/packs/categories/' + slug);
            if(response.status === 200) {
                categorie.value = response.data;
                loading.value = false;
                console.log(categorie.value);
            }
        } catch (error) {
            loading.value = false;
			if (error.response.status === 422) {
				errorMessage.value = error.response.data.detail;
			} 
            if (error.response.status === 404) {
				errorMessage.value = error.response.data.detail;
			} 
			if (error.response.status === 400) {
				errorMessage.value = error.response.detail;
			} 
			else {
				errorMessage.value = 'Erreur de traitement, vueillez réessayer plus tard.';
			}
        }
		
	};

	return {
		loading,
		categories,
		categorie,
        errorMessage,
		getCategories,
		getCategorie,
	};
}
