<template>
    <div class="overflow-hidden">
        <header id="topnav" class="defaultscroll sticky">
            <div class="">
                <!-- Logo container-->
                <router-link to="/forfait" class="logo">
                    <span class="logo-light-mode">
                        <img src="/assets/images/logo.png" class="l-dark" height="44" alt="">
                        <img src="/assets/images/logo.png" class="l-light" height="44" alt="">
                    </span>
                    <img src="/assets/images/logo.png" height="44" class="logo-dark-mode" alt="">
                </router-link>

                <!-- End Logo container-->
                <div class="menu-extras">
                    <div class="menu-item">
                        <!-- Mobile menu toggle-->
                        <!-- <a class="navbar-toggle" id="isToggle" onclick="toggleMenu()">
                            <div class="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </a> -->
                        <!-- End mobile menu toggle-->
                    </div>
                </div>

                <!--Login button Start-->
                <ul class="buy-button list-inline mb-0">
                    <li class="list-inline-item mb-0">
                        <router-link to="#" class="sub-menu-item text-capitalize text-light fs-6 fw-bold">CONNEXION
                        </router-link>
                    </li>
                </ul>
                <!--Login button End-->

                <!--end navigation-->
            </div>
            <!--end container-->
        </header>
        <div class="bg-home d-flex align-items-center"
            style="background: url('assets/images/hotspot/image_1.png') center no-repeat; background-size: 100%;">
            <!--end header-->
            <!--end header-->
            <div class="d-lg-none">
                <section class="app-content content d-table w-100" style="margin-top: -40px;">
                    <!-- <div class="row justify-content-center">
                                                                    <div class="offset-lg-4 col-lg-6">
                                                                        <div class="alert alert-outline-primary alert-pills" role="alert">
                                                                            <span class="badge rounded-pill bg-danger"> Info </span>
                                                                            <span class="alert-content"> Le service est en test ! </span>
                                                                        </div>
                                                                    </div>
                                                                </div> -->

                    <div class="row text-center">
                        <div class="col-lg-6 col-md-6 mx-auto">
                            <div class="row justify-content-center">
                                <div class="col-lg-6 col-12 col-sm-12 col-md-6 my-4 d-flex justify-content-center"
                                    v-for="(categorie, index) in categories" :key="index">
                                    <div class="card custom-card" @click="getSlug(categorie.slug)">
                                        <div class="card-body d-flex align-items-center justify-content-center">
                                            <h5 class="text-center fs-5 fw-bold custom-color">{{ categorie.name }}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" style="padding-top: 50px;">
                        <div class="col-lg-6 mx-auto">
                            <div class="text-sm text-center text-light">
                                Copyright © - wigui | Tous droits réservés
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div class="d-none d-lg-block">
                <section class="app-content content bg-half-100 d-table w-100">
                    <!-- <div class="row justify-content-center">
                                                                    <div class="offset-lg-4 col-lg-6">
                                                                        <div class="alert alert-outline-primary alert-pills" role="alert">
                                                                            <span class="badge rounded-pill bg-danger"> Info </span>
                                                                            <span class="alert-content"> Le service est en test ! </span>
                                                                        </div>
                                                                    </div>
                                                                </div> -->

                    <div class="row" style="margin-left: 70px;">
                        <div class="col-lg-6 col-md-6 mx-auto">
                            <div class="row justify-content-center">
                                <div class="col-lg-6 col-12 col-sm-12 col-md-6 my-4"
                                    v-for="(categorie, index) in categories" :key="index">
                                    <div class="card custom-card" @click="getSlug(categorie.slug)">
                                        <div class="card-body d-flex align-items-center justify-content-center">
                                            <h5 class="text-center fs-5 fw-bold custom-color">{{ categorie.name }}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" style="padding-top: 50px;">
                        <div class="col-lg-6 mx-auto">
                            <div class="text-sm text-center text-light">
                                Copyright © - wigui | Tous droits réservés
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </div>
    </div>
    <!--end header-->

</template>

<script>
    import useCategories from '../../services/categorieServices';
    import { onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from 'vuex';
    import { computed } from 'vue'
    export default {
        name: 'Forfait',
        setup() {
            const store = useStore();
            const categories = computed(() => store.state.categories);
            const { loading, getCategories } = useCategories();
            const router = useRouter()

            onMounted(async () => {
                if (!categories.value) {
                    await getCategories();
                }
            })

            const getSlug = (slug) => {
                localStorage.setItem('slug', slug);
                console.log('...')
                router.push({ name: 'Souscription' })
            }

            return {
                loading,
                categories,
                getSlug
            }
        }
    }
</script>
<style scoped>
    .custom-color {
        color: #707070;
        text-transform: uppercase;
    }

    #topnav.nav-sticky {
        background: #007ABD;
    }

    .custom-card {
        width: 271px;
        height: 155px;
        border-radius: 14px;
        cursor: pointer;
        box-shadow: 0px 15px 30px #00000029;
    }

    .custom-card:hover h5 {
        color: #007ABD;
        padding-right: 10px;

    }

    @media (max-width: 991px) {
        #topnav {
            background: #007ABD;
        }
    }
</style>